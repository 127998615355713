<template>
  <button
    class="button"
    :disabled="isLoading || isDisabled"
    :type="type"
    @click="$emit('click', $event)"
  >
    <slot v-if="!isLoading"></slot>

    <spinner-loader v-else />
  </button>
</template>

<script>
import SpinnerLoader from '@/components/next/spinner-loader.vue';

export default {
  components: { SpinnerLoader },

  props: {
    type: {
      type: String,
      default: 'button'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
